@import "../../scss/vars.scss";

.favorite-tutors {
				display: flex;
				gap: 20px;

				&__users {
								height: 100%;
								display: flex;
								flex-direction: column;
								gap: 10px;

								&-wrapper {
												display: flex;
												gap: 10px;
												flex-direction: column;
												justify-content: center;
												align-items: center;
												cursor: pointer;
								}

								&-picture {
												width: 50px;
												height: 50px;
												object-fit: cover;
												background: white;
												padding: 2px;
												border-radius: 50%;
												border: 1px solid $primary-color;
								}

								&-name {
												font-size: 12px;
								}
				}
}
