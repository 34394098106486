@import "../../../scss/vars";

.calendar-table.month {
    flex: 1;
    transition: .5s ease-in-out;

    table {
        td {
            height: 150px;
        }
    }

    tbody {
        display: table-row-group;
    }

    .calendar-table__slot {
        cursor: pointer;

        &:hover:not(.selected) {
            div {
                background: transparentize($black-1, .8);
            }
        }

        div {
            width: 30px;
            height: 30px;
            margin: auto;
            border-radius: 50%;
            border: unset !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.selected {
            div {
                background: $primary-color;
                color: white;
            }
        }
    }

    .calendar-table__slot-available, .calendar-table__slot-reserved {
        border-radius: 10px;
    }
}