@import "../../scss/vars";


.video-toolbar {
    flex-wrap: nowrap !important;

    &__btn {
        width: fit-content;
        padding: 1rem;
        border-radius: 50px;
        border: 0;
        background: $grey-1;
        cursor: pointer;

        &:hover {
            svg {
                fill: $primary-color;
            }
        }

        svg {
            width: 25px;
            height: 25px;
            fill: $black-3;
            transition: .3s ease-in-out;
        }
    }

    &__text-btn {
        width: 270px;
    }

    @media (max-width: 575.98px) {

        button {
            margin: 0 0.5rem;
            padding: 0 0.5rem;

            svg {
                width: 17px;
                height: 17px;
            }
        }

        .text-btn {
            padding-top: 0.5rem !important;
            padding-bottom: 0.4rem !important;
            font-size: 12px;
        }
    }
}