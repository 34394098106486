@import "../../scss/vars";

.lesson-details {
    height: 100%;
    min-height: 100vh;
    padding-top: 120px;

    h1 {
        font-weight: bold;
    }

    &__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include sm() {
            flex-direction: column;
            gap: 10px;
            justify-content: center;
        }
    }

    .notes {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

        @include darkMode {
            background: rgba(255, 255, 255, 0.15);
            box-shadow: unset;
        }
    }
}