@import "../../scss/vars";

.notification-page {
				display: flex;
				flex-direction: column;

				.latest-lectures {
								flex: 1;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
				}
}