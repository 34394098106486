@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('../resources/fonts/Inter-Regular.ttf') format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

// COLOR THEMES
$primary-color: #50a6ff;
$secondary-color: #34D885;
$accent-color: #ffb700;
$warning-color: #ff2436;



$light-blue: #B9E3F1;
$green-color: #25e662;

$green: $secondary-color;
$orange: #ff7f50;
$purple: #8a50ff;
$cyan: #50ffc2;
$yellow: #fddd5d;


$white-1: #f3f3f3;
$white-2: #e8e8e8;
$white-3: #d7d7d7;

$black-1: #0e0e0e;
$black-2: #171717;
$black-3: #1E1E1E;;
$black-4: #2c2c2c;

$grey: #ececec;
$grey-1: #ececec;
$grey-2: #dadada;
$grey-3: #aeaeae;
$grey-4: #616161;



$bg-gradient-dark: radial-gradient(107.70% 219.42% at -6.20% 192.01%, #000 0%, #2B2B36 100%);
$bg-gradient-light: radial-gradient(107.70% 219.42% at -6.20% 192.01%, #eeeeee 0%, #F5F5F5 100%);

$border-radius: 20px;

$box-shadow: 0 3px 10px 2px rgba(110, 110, 110, 0.1);
$box-shadow-dark: 0 3px 10px 2px rgba(110, 110, 110, 0.2);
$box-shadow-light: 0 3px 10px 2px rgba(152, 152, 152, 0.12);

$text: null;

// MARGINS
// Define the maximum margin value
$max-margin-value: 60;

// Calculate the number of possible values
$num-margin-values: $max-margin-value / 4;

// Generate the margin values map in multiples of 4px
$margin-values: ();
@for $i from 1 through $num-margin-values {
    $margin-values: map-merge($margin-values, (#{$i}: $i * 4px));
}

// SCSS mixin for generating margin classes with abbreviated names
@mixin generate-margin-classes {
    @each $key, $value in $margin-values {
        .ml-#{$key} {
            margin-left: $value;
        }
        .mr-#{$key} {
            margin-right: $value;
        }
        .mt-#{$key} {
            margin-top: $value;
        }
        .mb-#{$key} {
            margin-bottom: $value;
        }
    }
}

// Example usage:
@include generate-margin-classes;



// PADDINGS



// DARK AND LIGHT MODE

$darkMode: true;

.app {
    &.dark {
        background: $bg-gradient-dark;

        --text: #{$white-1};
        --text-secondary: #{$white-2};

        h1 {
            color: $white-1;
        }

        h2 {
            color: $white-2;
        }

        h3 {
            color: $white-2;
        }

        h4 {
            color: $white-3;
        }

        h5 {
            color: $white-3;
        }
    }

    &.light {
        background: $bg-gradient-light;

        --text: #{$black-1};
        --text-secondary: #{$black-2};

        h1 {
            color: $black-1;
        }

        h2 {
            color: $black-2;
        }

        h3 {
            color: $black-2;
        }

        h4 {
            color: $black-3;
        }

        h5 {
            color: $black-3;
        }
    }
}

p, div {
    color: var(--text);
}

@mixin darkMode {
    .app.dark & {
        @content;
    }
}

:root {
    --on: #6948ed;
    --off: #555;
    --scale: 16;
    --toggle-width: calc(var(--scale) * 3px);
    --toggle-height: calc(var(--scale) * 1.4125px);
    --border-width: calc(var(--scale) * 0.3px);
    --transition-time: 0.25s;
}

// TODO: remove this
#cta{
    @mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
        transition: $property $duration $ease;
    }
        
    button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
        &.learn-more {
            width: 12rem;
            height: auto;
            border: solid 2px;
            border-color: $primary-color;
            border-radius: 1.625rem;
            .circle {
            @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
            position: relative;
            display: block;
            margin: 0;
            width: 3rem;
            height: 3rem;
            background: $primary-color;
            border-radius: 1.625rem;

            .icon {
                @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                background: white;
                &.arrow {
                @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                left: 0.625rem;
                width: 1.125rem;
                height: 0.125rem;
                background: none;
                &::before {
                    position: absolute;
                    content: '';
                    top: -0.25rem;
                    right: 0.0625rem;
                    width: 0.625rem;
                    height: 0.625rem;
                    border-top: 0.125rem solid white;
                    border-right: 0.125rem solid white;
                    transform: rotate(45deg);
                }
                }
            }
            }
            .button-text {
            @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0.75rem 0;
            margin: 0 0 0 1.85rem;
            color: $primary-color;
            font-weight: 700;
            line-height: 1.6;
            text-align: center;
            text-transform: uppercase;
            }
        }
        &:hover {
            .circle {
            width: 100%;
            .icon {
                &.arrow {
                background: white;
                transform: translate(1rem, 0);
                }
            }
            }
            .button-text {
            color: white;
            }
        }
    }
        
}


// RESPONSIVE

// Small devices
@mixin sm {
    @media (max-width: 576px) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: 768px) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: 992px) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: 992px) and (max-width: 1199.98px) {
        @content;
    }
}

// Extra large devices
@mixin xxl {
    @media (min-width: 1200px) and (max-width: 1399.98px) {
        @content;
    }
}