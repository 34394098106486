@import "../../../scss/vars";

.calendar-table {
    height: 100%;

    // day mode
    &--1 {
        td {
            height: 40px !important;
        }
    }

    @include darkMode() {
        color: white;
    }

    table {
        background: $white-1;
        text-align: center;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
        border-collapse: collapse;
        border-radius: 17px;
        overflow: hidden;

        @include darkMode() {
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.1);
        }

        th, td {
            width: calc(100% / 9);
            color: $black-1;

            @include darkMode() {
                border: 1px solid rgba(255, 255, 255, 0.1);
                color: $grey-1;
            }

            &:first-child {
                border-left: unset;
            }

            &:last-child {
                border-right: unset;
            }
        }

        th {
            border-left: 1px solid rgba(0, 0, 0, 0.15);
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding: 12px 0;
            font-weight: normal;
            background: transparentize($grey-3, .8);

            @include darkMode {
                background: transparentize($white-1, .8);
            }
        }

        td {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-top: unset;
            height: 60px;
        }

        tr {
            position: relative;
            transition: 200ms ease-in-out;
            user-select: none;

            display: table;
            margin: 0;
            width: 100%;

            //&:hover {
            //    background: $grey-2;
            //    color: black;
            //}

            &.current {
                color: $primary-color;
            }

            &.selected {
                background: $grey-2;
                color: black;
            }

            &.booked {
            }
        }

        tbody {
            height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 50vh;
            display: block;
            margin-right: -8px;
        }
    }

    &__external-td {
        cursor: pointer;
    }
}