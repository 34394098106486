@import "../../scss/vars";


.referral {
    margin-top: 5rem;
    margin-bottom: 5rem;

    &-firstparagraph {
        @media (max-width: 767.98px) {
            text-align: center;
        }
    }

    &-first-row {

        @media (max-width: 767.98px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &-copywrapper {
        box-sizing: border-box;
        border: 0;
        border-radius: 7px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        background-color: $white-2;
        color: $primary-color;
        margin: 1rem 0;
        padding: 0.5rem;

        @media (max-width: 480px) {
            flex-direction: column;
            text-align: center;
            line-height: 4rem;
        }
    }

    &-copy {
        color: $primary-color;
        font-size: 13px;

        &-popup {
            position: absolute;
            color: $primary-color;
            font-size: 13px;
        }
    }


    &-buttn {
        height: 50px;
        min-width: 127px;
        border-radius: 12px;
        font-size: 14px;
        color: white;

        p {
            color: white;
        }

        svg {
            margin-right: 0.5rem;
            font-size: 20px;
        }
    }

    &-item {
        height: 70px;
        width: 70px;
        box-sizing: border-box;
        border: 0;
        border-radius: 7px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        font-size: 25px;
        background-color: $white-2;
        color: $primary-color;
        margin: 1rem 0 1rem 1rem;
        transition: 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 0 30px 0 $primary-color;
        }
    }

    &-second-row {
        h6 {
            font-size: 13px;
        }
    }

    &-title-div {
        @media (max-width: 575.98px) {
            flex-direction: column;

            h6 {
                padding-top: 0.5rem;
                text-align: center;
            }
        }
    }


    &-guide-steps {
        box-sizing: border-box;
        border: 0;
        border-radius: 50%;
        background-color: $black-1;
        margin-right: 0.8rem;
        height: 40px;
        width: 40px;

        @media (max-width: 575.98px) {
            margin-right: 0;
            min-height: 30px;
            max-height: 30px;
            min-width: 30px;
            max-width: 30px;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            margin-right: 0.5rem;
            min-height: 30px;
            max-height: 30px;
            min-width: 30px;
            max-width: 30px;
        }

        @include darkMode {
            background-color: white;
        }

        p {
            color: white;

            @include darkMode{
                color: $black-1;
            }
        }

        &-text {
            font-size: 12px;
            padding: 0 0.5rem;

            @media (max-width: 575.98px) {
                text-align: center;
                font-size: 11px;
            }
        }
    }
}