@import "../../../scss/vars";

.calendar-table__confirmation {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100px;
    background: $grey-2;
    backdrop-filter: blur(10px);
    z-index: 1000;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transition: 400ms ease-in-out;

    &.open {
        bottom: 0;
    }

    @include darkMode {
        background: transparentize($black-2, .2);
    }

    &__close {
        background: $grey-2;
        border-radius: 50%;
        padding: 10px;
        margin-right: 20%;
        cursor: pointer;
        transition: 200ms ease-in-out;

        &:hover {
            background: $primary-color;

            path {
                stroke: white;
            }
        }
    }
}