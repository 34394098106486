@import "../../scss/vars";

.switcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 11px;
    background-color: $white-1;
    height: 55px;
    cursor: pointer;
    user-select: none;
    border: 1px solid $grey-2;

    @include darkMode {
        background-color: rgba(231, 231, 231, 0.15);
        border: unset;
    }

    &__text {
        height: 100%;
        font-size: 16px;
        margin: 0 !important;
        border-left: 1px solid $grey-2;
        border-right: 1px solid $grey-2;
        width: 150px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 5px;
        color: $black-1;

        @include darkMode {
            border-color: rgba(0, 0, 0, 0.15);
            color: $grey-1;
        }
    }

    &__leftBtn {
        color: $black-3;
        width: 60px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            padding: 20px 0 20px 8px;
        }

        &:hover {
            color: $primary-color;
        }

        @include darkMode {
            color: $grey-2;

            &:hover {
                color: $primary-color;
            }
        }
    }

    &__rightBtn {
        color: $black-3;
        width: 60px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            padding: 20px 0;
        }

        &:hover {
            color: $primary-color;
        }

        @include darkMode {
            color: $grey-2;

            &:hover {
                color: $primary-color;
            }
        }
    }
}