@import "../../scss/vars";

.waveform {
    height: 88px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 17px;
    overflow: hidden;

    @include darkMode {
        background: rgba(255, 255, 255, 0.15);
    }
}