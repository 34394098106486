@import "../../scss/vars";

.schedule {
    height: 100%;

    &__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include sm() {
            flex-direction: column;
            gap: 10px;
            justify-content: center;
        }
    }

    &__sidebar {
        border-radius: 12px;
        background: $grey-1;
        min-height: 100%;
        max-width: 0;
        overflow: hidden;
        padding: 0;
        width: 100%;
        transition: .3s ease-in-out;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        position: relative;
        flex-shrink: 1;

        @include darkMode {
            background: $black-2;
        }

        &.open {
            max-width: 270px;
            margin-left: 20px;
        }

        .calendar-table__slot-box {
            &:hover {
                transform: unset;
            }
        }

        .calendar-table__slot-available {
            height: 40px;
        }
    }
}