@import "../../scss/vars";

$margin-right: 20px;
$spacing: 3px;

.theme-toggle {
    background: white;
    border-radius: 40px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $margin-right;
    transition: all 200ms ease-in-out;
    border: 1px solid $grey-2;
    cursor: pointer;
    position: relative;

    &:hover {
        svg {
            transition: all 200ms ease-in-out;
            opacity: .8;
        }
    }

    @include darkMode() {
        background: rgba(255, 255, 255, 0.2);
        border: unset;
    }

    &__button {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background: $grey-4;
        transition: all 300ms ease-in-out;
        position: absolute;
        left: $spacing;

        @include darkMode() {
            background: transparent;
            left: calc(100% - $spacing);
            transform: translateX(-100%);
        }
    }

    &__sun {
        @include darkMode() {
            display: none;
        }

        svg {
            fill: white;
        }
    }

    &__moon {
        display: none;

        @include darkMode() {
            display: block;
        }

        svg {
            fill: #ff9f3d;
        }
    }
}