@import "../../../../scss/vars";

.general {
    min-height: 1000px;

    &-title {

        @media (max-width: 575.98px) {
            text-align: center;
        }
    }
}