@import "../../scss/vars.scss";

.dashboard {
    &__box {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 15px;
        padding: 25px;

        @include darkMode() {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }
}