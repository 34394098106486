@import "../../scss/vars";

.hero-features {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
    gap: 26px;

    @include lg {
        flex-direction: column;
    }

    &__box {
        padding: 35px;
        background: white;
        border-radius: 20px;
        text-align: center;

        @include darkMode {
            background: $grey-2;
        }
    }

    &__title {
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 16px;
        color: $black-1 !important;

        @include darkMode {
            //color: $white-1 !important;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 16px;
        color: $grey-4 !important;

        @include darkMode {
            //color: $white-2 !important;
        }
    }

    &__img {
        width: 50px;
        margin-bottom: 16px;
    }
}