@import "../Register/Register";

.login-page {
    width: 100%;
    height: 100vh;
    z-index: -2;
    display: flex;
    padding-top: 60px;

    &__background {
        width: 50%;
        height: 100%;
        object-fit: cover;

        @include lg {
            display: none;
        }
    }

    &__final {
        width: 40vw;
        position: absolute;
        left: 30vw;
        top: 7.5vh;
        height: calc(100vh - 15vh);
        border-radius: 1.25rem;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 1);
        padding: 10px;
        -webkit-box-shadow: 0px 5px 15px 8px rgba(0, 0, 0, 0.61);
        box-shadow: 0px 5px 15px 8px rgba(0, 0, 0, 0.61);
        opacity: 0;
        transform: translateY(120vw);

        &.slide-down {
            transform: translateY(0);
            transition: 0.9s ease-in-out;
            opacity: 1;
        }
    }

    &__left {
        @extend .register__left;

        &-title {
            margin-bottom: 80px;

            @include darkMode {

            }
        }

        &-form {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-label {
                font-weight: 500;
                letter-spacing: 0.06rem;
                font-size: 18px;
            }
        }
    }
}