@import "../../scss/vars";

.lesson-status {
    &__wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__user-pic {
        width: 75px;
        height: 75px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        flex-shrink: 0;
    }

    &__status {
        background: #6CDF93;
        color: #155F2E;
        font-size: 10px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        padding: 6px 32px;
        border-radius: 50px;
        width: auto;
    }

}