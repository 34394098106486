@import "../../scss/vars";

.top-tutors {
    padding-bottom: 100px;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 320px;
        padding: 0 20px;
        cursor: pointer;
        transition: .3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

        @include xl {
            width: 300px;
        }

        @include lg {
            padding: 0 20px 0 0;
            width: 250px;
        }
    }

    &__user-pic {
        width: 100%;
        height: 350px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 50px 50px 0 0;
        background: #f9b44d;
        object-fit: cover;

        @include lg {
            width: 250px;
            height: 300px;
        }
    }

    &__footer {
        box-shadow: 0 5px 12px 0 #34D885;
        width: 100%;
        height: 91px;
        background: var(--secondary-color, #34D885);
        border-radius: 0 0 50px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include lg {
            width: 250px;
        }
    }

    &__name {
        font-size: 28px;
        color: white;
    }

    &__star {
        font-size: 14px;
        color: white;
    }

    &__info {
        font-size: 14px;
        color: white;
    }

    // slick styling

    .slick-track {
        padding: 20px 0;
        height: 550px;
    }

    .slick-list {
        margin: 0 20px;

        @include lg {
            margin: 0;
        }
    }

    .slick-prev,
    .slick-next {
        color: $black-2;
        background-color: $grey-2;
        border-radius: 50%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        transition: 200ms ease-in-out;

        @include darkMode {
            background-color: $grey-1;
        }

        &:before {
            display: none;
        }

        &:hover {
            background-color: $primary-color;
            color: white;

            @include darkMode {
                background-color: $primary-color;
                color: white;
            }
        }
    }

    .slick-prev {
        left: -50px;
    }

    .slick-next {
        right: -50px;
    }

    .slick-dots {
        background: $grey-2;
        width: auto;
        height: 50px;
        border-radius: 50px;
        padding: 10px 20px;
        left: 50%;
        top: calc(100% + 20px);
        transform: translateX(-50%);
    }
}