@import "src/scss/vars";

.logout {
				display: flex;
				padding-top: 0;
				justify-content: center;
				transition: all .2s ease-in-out;
				cursor: pointer;

				@include darkMode() {
								color: white;
				}

				span {
								font-size: 14px;
								margin-right: 8px;
								line-height: 26px;
				}

				svg {
								width: 20px;
				}

				&:hover {
								cursor: pointer;
								color: $primary-color;

								@include darkMode() {
												color: $grey-2;
								}
				}
}