@import "src/scss/vars";

.navbar-app {
    position: relative;
    top: 0;
    left: 0;
    color: black;
    height: 100vh;
    width: var(--navbar-app-width);
    padding: 0 30px;
    justify-content: center;
    z-index: 999;
    transition: width 300ms ease-in;

    @include darkMode() {
    }

    @include md {
        position: fixed;

        .navbar-container__links-item {
            display: flex;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }

    &.minimized {
        width: var(--navbar-app-shrink-width);

        .navbar-container__links-item {
            a {
                align-items: center;

                svg {
                    margin: 0;
                }

                span {
                    display: none;
                }
            }
        }

        .navbar-app-container__user-icon img {
            width: 35px;
            height: 35px;
        }

        @include md {
            &:before {
                display: none;
            }

            .navbar-app-container {
                > *:not(.navbar-app-container__menu-icon) {
                    display: none;
                }
            }
        }

        .tickets {
            flex-direction: column;
            width: 60px;
            padding: 10px;
            gap: 3px;

            span {
                display: none;
            }
        }
    }

    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

        @include darkMode() {
            background-color: rgba(0, 0, 0, 0.15);

            @include md {
                background-color: rgba(0, 0, 0, 0.75);
            }
        }
    }

    .main-logo {
        margin: 0;
        display: flex;
        justify-content: center;

        img {
            height: 60px;
            object-fit: cover;
            padding: 10px;
        }

        a {
            padding: 0;

            &:hover {
                background-color: transparent;
                cursor: pointer;
            }
        }
    }

    &-container {
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 30px 0;

        &__logo {
            margin-left: 0;
        }

        &__menu-icon {
            display: none;

            @media screen and (max-width: 992px) {
                display: flex;
                justify-content: flex-start;
            }
        }

        &__links {
            display: flex;
            margin-bottom: 0;
            align-items: center;
            padding: 0;
            flex-direction: column;
            gap: 20px;

            &-item {
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }

            li {
                margin: 0 15px;
                list-style-type: none;
                width: 100%;
                text-align: center;
                padding: 10px 0;

                &.active {
                    background: transparentize($primary-color, .6);
                    border-radius: 50px;

                    a {
                        color: $primary-color !important;
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 400;
                    transition: all .2s ease-in-out;
                    color: $black-4;
                    display: flex;
                    justify-content: center;

                    @include darkMode() {
                        color: $grey-2;
                    }

                    &:hover {
                        color: $primary-color;

                        @include darkMode() {
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        &__links-mobile {
            //display: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: white;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 0;
            padding: 0;
            list-style-type: none;
            gap: 10px;
            z-index: -3;
            transition: all 500ms cubic-bezier(1, 0.36, 0.41, 0.9);
            backdrop-filter: blur(10px) opacity(0);
            opacity: 0;
            overflow: hidden;
            border-radius: 10px;

            &.active {
                //display: flex;
                width: 100vw;
                backdrop-filter: blur(10px) opacity(1);
                background: rgba(255, 255, 255, 0.7);
                opacity: 1;
                border-radius: 0;

                .navbar-container__links-mobile-item {
                    opacity: 1;
                }
            }

            &-item {
                font-size: 1rem;
                transition: all 200ms ease-in-out;
                opacity: 0;

                a {
                    text-decoration: none;
                    color: black;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }

        &__user {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            position: relative;

            &-icon {
                display: flex;
                gap: 10px;
                text-decoration: none;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;

                    &:hover {
                        cursor: pointer;
                    }
                }

                div {
                    font-size: 14px;
                    width: calc(100% - 60px);
                }
            }
        }
    }

    .theme-toggle {
        margin: 0;
    }

    .notifications {
        position: relative;

        .navbar-notifications {
            left: 6rem;
            top: calc(-50vh - 40px);
            max-height: 50vh;
            max-width: 0;

            &.active {
                max-width: 50vw;
            }
        }
    }

    &-popup {
        position: absolute;
        top: 80px;
        right: 40px;
        width: 300px;
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
        -webkit-box-shadow: 0 6px 15px 8px rgba(0, 0, 0, 0.42);
        box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.22);
        border-radius: 1.25rem;
        height: 0;
        overflow: hidden;
        transition: 0.4s ease-in-out;
        opacity: 0;
        background-color: rgba(255, 255, 255, 0.8);

        @include darkMode() {
            background-color: rgba(0, 0, 0, 0.2);
        }

        &.active {
            height: 460px;
            opacity: 1;
            padding: 30px;
        }

        &__user {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            align-items: center;
            justify-content: center;

            img {
                height: 80px;
                width: 80px;
                border-radius: 50%;
                object-fit: cover;
            }

            label {
                padding: 10px;
                font-size: 18px;
                font-weight: 400;
                display: flex;

                @include darkMode() {
                    color: white;
                }

                img {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &__button {
            padding: 10px;
            margin: 20px 0;
            border-radius: 45px;
            background-color: rgba(255, 255, 255, 0.418);
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
            color: black;
            transition: 0.4s ease-in-out;
            width: 100%;
            text-decoration: none;
            display: block;
            text-align: center;

            &:hover {
                background-color: $primary-color;
                box-shadow: 0 15px 20px $primary-color;
                color: #fff;
                transform: scale(1.08);
                cursor: pointer;
            }
        }
    }

    &__toggle-bar {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 20px;
        right: 0;
        transform: translateX(50%);
        background: $grey-2;
        color: $black-2;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            color: $primary-color;
        }

        @include md {
            display: none;
        }
    }
}