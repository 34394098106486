@import "../../scss/vars";


.priceplans {
				padding-bottom: 50px;

				&-carditem {
								width: 400px;
								height: 550px;
								border: 0;
								border-radius: 17px;
								box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
								padding: 2rem;
								margin: 0 20px 20px 0;

								@include darkMode {
												background-color: #17171e;
												box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
												//box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.25);
								}
				}

				&-title {
								height: 30%;
								border-bottom: 1px solid black;
								border-bottom-style: dashed;

								&-h3-1 {
												color: #ff8000 !important;
								}

								&-h3-2 {
												color: springgreen !important;
								}

								&-h3-3 {
												color: royalblue !important;
								}

								@include darkMode {
												border-bottom: 1px solid white;
												border-bottom-style: dashed;
								}
				}

				&-features {
								margin-top: 1.5rem;

								div {
												margin-top: 0.5rem;
												gap: 10px;

												p {
																font-size: 13px;
												}

								}

								svg {
												min-height: 20px;
												min-width: 20px;
												color: springgreen;
								}
				}

				&-button {

								p {
												font-size: 12px;
												color: $primary-color;
								}
				}

				.swiper-slide{
								width: fit-content;
								height: 550px;
								background: white;

								@include darkMode {
												background: $black-2;
								}
				}

				.swiper-wrapper {
								padding-bottom: 50px;
								padding-top: 20px;
				}
}