@import "../../scss/vars";


.mini-calendar {
    * {
        color: white !important;
    }

    box-sizing: border-box;
    border: 0;
    border-radius: 17px;
    background: $secondary-color;
    box-shadow: -50px -50px 150px 50px rgb(52, 216, 133);
    width: 110px;
    height: 100px;

    //@include darkMode {
    //    background: linear-gradient(135deg, #5350FF, rgba(255, 139, 73, 0.5));
    //}

    h1 {
        font-size: 42px;
        font-weight: 700;
        line-height: 42px;
    }

    h4 {
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
    }
}