@import "../../scss/main";

.phonenumber {
    border: 0 !important;
    border-radius: 10px !important;
    width: 100% !important;
    padding: 10px 14px 10px 80px !important;

    &:focus {
        box-shadow: 10px 10px 20px #d1d1d1,
        -5px -5px 20px rgba(244, 244, 244, 0.48) !important;
    }
}

.react-tel-input .selected-flag {
    padding-left: 30px !important;
}