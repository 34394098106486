@import "../../scss/vars";

.tutor-info {

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 320px;
        padding: 0 20px;
    }

    &__user-pic {
        width: 350px;
        height: 528px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        object-fit: cover;
        background: #f9b44d;

        @include lg {
            width: 400px;
            height: 480px;
            margin: auto;
        }

        @include md {
            width: 100%;
            height: auto;
        }
    }

    &__video {
        @extend .tutor-info__user-pic;
    }

    &__top {
        display: flex;
        margin-bottom: 60px;

        @include lg {
            flex-direction: column;
            gap: 20px;
            align-items: center;
        }
    }

    &__pic-wrapper {
        position: relative;
    }

    &__play-button {
        position: absolute;
        top: 0;
        left: 100%;
        border-radius: 50%;
        background: $grey-2;
        width: 106px;
        height: 106px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        cursor: pointer;
        transform: translate(-50%, -50%);
        transition: 300ms ease-in-out;

        &:hover {
            background: black;
            color: white;
        }

        @include md {
            width: 60px;
            height: 60px;
        }
    }

    &__description {
        text-align: justify;

        @include xl {
            font-size: 20px;
        }

        @include lg {
            text-align: center;
        }

        @include md {
            padding: 0 40px;
            font-size: 18px;
        }
    }

    &__star {
        display: flex;
        align-items: center;

        svg {
            color: gold;
        }
    }

    &__right {
        margin-left: 84px;

        @include lg {
            margin: 0;
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            @include xl {
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
            }

            @include lg {
                align-items: center;
            }
        }
    }

    &__table {
        background: $grey-2;
        border-radius: 17px;

        @include darkMode {
            background: rgba(255, 255, 255, 0.15);
        }

        table {
            tbody {
                @include md {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }

            width: 100%;

            td {
                padding: 14px;

                @include md {
                    padding: 8px 14px;
                }
            }

            tr {
                @include md {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }
    }
}