@import "src/scss/vars";

.notifications {
				.navbar-notifications {
								overflow: hidden;
								width: 300px;
								left: unset;
								right: 95px;
								top: 80px;
								margin-left: -30px;
								padding: 0;
								position: absolute;
								z-index: -1;
								background-color: rgba(255, 255, 255, 0.8);
								box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.22);
								border-radius: 1.25rem;
								transition: 0.4s ease-in-out;
								height: 50vh;
								opacity: 0;
								max-height: 0;
								overflow-y: auto;
								backdrop-filter: blur(10px);
								display: flex;
								flex-direction: column;
								justify-content: space-around;

								@include darkMode() {
												background: rgba(0, 0, 0, 0.8);
								}

								@include md {
												right: 46px;
								}

								&::-webkit-scrollbar {
												display: none;
								}

								&.active {
												max-height: 50vh;
												opacity: 1;
								}

								&__list {
												list-style: none;
												padding: 0;
												margin: 0;

												li {
																border-radius: 1.25rem;
																margin-bottom: 15px;
																padding: 14px 20px;

																&:last-child {
																				margin-bottom: 0;
																}

																.item-icon {
																				display: flex;
																				align-items: center;
																				margin-right: 10px;

																				img {
																								width: 30px;
																								height: 30px;
																								object-fit: cover;
																								border-radius: 50%;
																				}
																}

																.item-content {
																				display: flex;
																				flex-direction: column;
																				text-align: left;

																				&__title {
																								font-weight: 400;
																								font-size: 16px;
																								color: black;

																								@include darkMode {
																												color: white;
																								}
																				}

																				&__text {
																								font-size: 14px;
																								color: rgba(0, 0, 0, 0.6);

																								@include darkMode {
																												color: white;
																								}
																				}
																}

																.date {
																				margin-top: 5px;
																				text-align: start;
																				font-size: .8rem;
																				color: darken($primary-color, 10%);
																}
												}
								}

								&__empty-img {
												width: 100%;
												max-width: 150px;
								}
				}
}