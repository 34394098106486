@import "../../scss/vars";

.profile {

    @media (max-width: 575.98px) {
        flex-direction: column;

    }

    &-left {
        background-color: $grey-1;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        padding: 1.5rem 1rem;
        margin: 3rem 0;
        transition: .3s ease-in-out;

        @media (max-width: 575.98px) {
            margin-bottom: 1rem;

        }

        @include darkMode {
            background-color: transparentize($black-2, .85);
        }

        ul {
            list-style: none;
            padding-left: 0 !important;
            text-align: center;

            li {
                padding: 0.7rem 0;
                transition: 0.3s ease-in-out;

                &:hover {
                    cursor: pointer;
                    color: var(--primary-color, #50A6FF);
                    transform: scale(1.24);
                }

                @media (max-width: 767.98px) {
                    font-size: 13px;
                }
            }
        }
    }

    &-toggle {
        margin-top: 20px;
    }

    &-image {
        position: relative;
        width: 150px;

        &__img {
            border-radius: 50%;
            height: 150px;
            width: 150px;
            object-fit: cover;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            width: 100px;

            &__img {
                width: 100px;
                height: 100px;
            }
        }
    }

    &-name {
        margin: 30px 0;

        h2 {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            margin: 0 5px;

            @media (min-width: 576px) and (max-width: 767.98px) {
                font-size: 15px;
            }
        }
    }

    &-type {
        margin-top: 10px;
        border: 1px solid $primary-color;
        border-radius: $border-radius;
        padding: 2px 10px;

        span {
            font-size: 14px;
        }
    }

    &-modal_wrapper {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.37);
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }

    &-modal {
        width: auto;
        height: auto;
        background: white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 50px;
    }

    &-right {
        margin: 3rem 0;

        @media (max-width: 575.98px) {
            margin-top: 1rem;

        }
    }
}