@import "../../../scss/vars";

.calendar-table__slot {
    max-height: 150px;

    &.selected {
        div {
            border-radius: 10px;
            border: 2px solid $primary-color !important;
            background: transparentize($primary-color, .6);
        }
    }

    &-wrapper {
        height: 100%;
    }

    &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        z-index: 1;
        font-size: 14px;
        border-radius: 14px;
        height: 100%;
        transition: .3s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }

    &-available {
        background: $primary-color;
        color: white;
        box-shadow: -10px -10px 100px 0 transparentize($primary-color, .6);
    }

    &-reserved {
        background: $grey-1;
        color: $black-2;
    }

    &-yours {
        background: $green;
        color: white;
    }

    &-user {
        background: $accent-color;
        box-shadow: 0 4px 100px 0 $accent-color;

        &.orange {
            background: $orange;
            box-shadow: 0 4px 100px 0 $orange;
        }

        &.green {
            background: $green;
            box-shadow: 0 4px 100px 0 $green;
        }

        &.purple {
            background: $purple;
            box-shadow: 0 4px 100px 0 $purple;
        }


        color: white;
        padding: 10px;
        gap: 5px;
        max-height: 150px;
        text-align: left;

        &-img {
            border-radius: 50%;
            object-fit: cover;
            width: 30px;
            height: 30px;
        }
    }
}