@import "../../scss/vars";

.chat {
    display: flex;
    flex-direction: column;
    height: 535px;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
    background: white;

    @include darkMode {
        background: transparentize($black-3, .6);
    }

    &__wrap {
        display: flex;
        height: 100%;
    }

    &__main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__sidebar {
        width: 350px;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 5px;
        gap: 5px;
        //display: none;
    }

    &__room-item {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        border-radius: $border-radius;
        transition: all .2s ease-in-out;
        padding: 10px;
        cursor: pointer;

        &:hover {
            //background: lighten($primary-color, 20);
            //color: white;
            box-shadow: $box-shadow;
        }

        &.selected {
            //background: $primary-color;
            //color: white;
            border-left: 5px solid $primary-color;
            box-shadow: $box-shadow;
        }

        img {
            width: 40px;
            height: 40px;
        }

        .chat__user-name {
            font-weight: 500;
            flex: 1;
            text-align: left;
        }
    }

    &__chat-info {

    }

    &__last-msg {
        opacity: .8;
        text-align: left;
    }

    &__window {
        flex: 1;
        overflow-y: auto;
        padding: 1em 1em 0 1em;
        height: 100%;
    }

    &__message {
        margin-bottom: 1em;
        display: flex;
        justify-content: flex-start;
        gap: 14px;
        align-items: center;

        &.self {
            flex-direction: row-reverse;

            .chat__message-msg {
                background: $primary-color;
                color: white;
            }
        }

        &-img {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            object-fit: cover;
        }

        &-msg {
            border-radius: 50px;
            background: $grey-1;
            padding: 15px 22px;
            color: $black-2;
        }
    }

    &__input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        gap: 20px;
        position: absolute;
        bottom: 0;
        width: calc(100% - 8px);

        input {
            border-radius: 50px;
            background: $grey-1;
            box-shadow: unset;
            padding: 20px;
        }

        &-field {
            flex: 1;
            margin-right: 1em;
        }
    }

    &__send-btn {
        width: 52px;
        height: 52px;
        background: none;
        border: none;
        outline: none;
        color: white;
        position: absolute;
        right: 28px;
        background: $primary-color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            margin-left: 5px;
        }

        :hover {

        }
    }

    &__scroll-view {
        width: 100%;
        height: 180px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding: 10px;
        background: $primary-color;
        color: white;
        align-items: center;
        height: 100px;
        display: none;
    }

    &__participants {
        max-width: 300px;
        padding: 20px;
        display: flex;
        gap: 20px;
    }

    &__possible-participants {
        border-radius: $border-radius;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
        padding: 20px;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 5px;
        top: calc(100% + 10px);
        left: 20px;
        color: black;
        background: white;
        z-index: 1;
        max-height: 300px;
        overflow: auto;
    }

    &__select-item {
        display: flex;
        gap: 10px;
        cursor: pointer;
        align-items: center;
        text-align: left;

        img {
            background: white;
            border: none;
        }
    }

    &__user-name {

    }

    &__search {
        max-height: 40px;
    }

    &__no-messages {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
