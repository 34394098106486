.about-us {

				&__line {
								position: absolute;
								top: 60px;
								left: 20px;
				}

				&__title {
								font-size: 54px;
								font-family: serif;
								font-weight: bold;
								text-align: center;
				}

				&__img {
								width: 100%;
								max-width: 500px;
								border-radius: 12px;
								mix-blend-mode: multiply;
				}

				&__section {
								min-height: 75vh;
				}
}