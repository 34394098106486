@import "../../scss/vars";

.video-info {
    &__wrapper {
        display: flex;
        align-items: center;
        gap: 33px;
    }

    &__user-pic {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        object-fit: cover;
    }

    &__record {
        display: flex;
        align-items: center;
        gap: 10px;

        &-icon {
            width: 34px;
            height: 34px;
            background: #ED4E4E;
            border-radius: 50%;
        }
    }
}