@import "../../scss/vars";
@import "../../scss/main";

.country-selector {
    .select {
        &__control {
            @extend .input;
            padding: 5px;
        }
    }

    &__country {
        color: black;
    }
}