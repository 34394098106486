@import "../../scss/vars";

.footer {
    background-color: $grey-1;

    @include darkMode() {
        background-color: unset;
        border-top: 1px solid transparentize($grey-1, .9);
    }

    * {
        color: $black-2;

        @include darkMode {
            color: $white-2;
        }
    }

    .footer-row-1 {
        box-sizing: border-box;
        border-bottom: 1px solid transparentize($black-1, .9);

        @include darkMode {
            border-bottom: 1px solid transparentize($grey-1, .9);
        }

        padding: 0 2rem;

        * {
            padding: 1rem 0;
        }

        .r1-c1 {
            h2 {
                padding-left: 1.5rem;
                margin-bottom: 0 !important;
            }

            img {
                width: 60px;
                height: auto;
            }
        }

        .r1-c2 {

            .navLink {
                text-decoration: none;

                &:hover {
                    cursor: pointer;
                    text-decoration-color: white;
                    text-decoration: none;
                    color: $primary-color;
                }
            }
        }

        @media (max-width: 575.98px) {
            justify-content: center !important;
            .r1-c1 {
                justify-content: center !important;

                //h2 {
                //    font-size: 1.5rem;
                //}

                img {
                    width: 40px;
                    height: auto;
                }
            }

            .r1-c2 {
                .navLink {
                    font-size: 0.7rem;
                    padding: 0 0 !important;
                }
            }
        }

        @media (min-width: 576px) and (max-width: 991.98px) {
            justify-content: center !important;
            .r1-c1 {
                justify-content: center !important;

                img {
                    width: 60px;
                    height: auto;
                }
            }

            .r1-c2 {
                .navLink {
                    padding: 0 0 !important;
                }
            }
        }
    }

    .footer-row-2 {
        padding: 0 2rem;

        * {
            padding-top: 15px;
            padding-bottom: 15px;
        }


        .r2-c1 {
            * {
                padding: 15px 0;
            }

            h4 {
                margin-bottom: 0 !important;
            }


        }

        .r2-c2 {
            p {
                margin-bottom: 0 !important;
            }
        }

        @media (min-width: 576px) and (max-width: 991.98px) {
            .r2-c1 {
                h4 {
                    font-size: 1rem;
                }
            }

            .r2-c2 {
                p {
                    font-size: 0.8rem;
                }
            }
        }

        @media (max-width: 575.98px) {
            flex-direction: column !important;

            .r2-c1 {

                h4 {
                    font-size: 1rem;
                    text-align: center !important;
                    text-wrap: normal !important;
                }

                .icons-wrapper {
                    justify-content: center !important;

                    .icon-element {
                        width: 50px;
                        height: 50px;
                    }
                }

            }

            .r2-c2 {
                justify-content: center !important;
                p {
                    font-size: 0.8rem;
                }
            }
        }
    }
}