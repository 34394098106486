@import "../../scss/vars";

.main-modal {
	padding: 0;
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	border-radius: 0;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparentize($white-1, .8);
	backdrop-filter: blur(10px);
				z-index: 1000;

	&:not(.show) {
		background: transparent;
		backdrop-filter: unset;

		.main-modal__body {
			transform: translateY(-150%);
		}
	}

	@include darkMode {
		background: transparentize($black-2, .8);
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 1;
		box-shadow: 0 0 5px 0 transparentize($black-2, .8);
		border-radius: 50%;
		margin: 0;
		cursor: pointer;

		@include darkMode {
			box-shadow: 0 0 5px 0 $grey-3;

			svg {
				fill: $grey-3;
			}
		}

		svg {
			margin: 0;
			fill: $black-3;
			transition: .3s ease-in-out;
		}

		&:hover {
			svg {
				fill: $primary-color !important;
			}
		}
	}

	&__body {
		height: 80%;
		width: 80%;
		border-radius: 15px;
		background: $white-1;
		padding: 2rem;
		box-shadow: 0 0 10px 0 transparentize($black-1, .5);
		position: relative;
		transition: .3s ease-in-out;

		@include darkMode {
			background: $black-2;
		}
	}

	//&.small {
	//	height: 40vh;
	//	width: 40vw;
	//	top: calc(50% - 20vh);
	//	left: calc(50% - 20vw);
	//}
	//
	//&.medium {
	//	height: 60vh;
	//	width: 60vw;
	//	top: calc(50% - 30vh);
	//	left: calc(50% - 30vw);
	//}
	//
	//&.full {
	//	height: 100vh;
	//	width: 100vw;
	//	top: 0;
	//	left: 0;
	//}
}