@import "../../scss/vars";

.notes {
    display: flex;
    flex-direction: column;
    height: 535px;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
    background: white;

    @include darkMode {
        background: transparentize($black-3, .6);
    }
}