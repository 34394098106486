@import "../../scss/vars";


.latest-lectures {

    &__user-wrapper {
        box-sizing: border-box;
        border: 0;
        border-radius: 12px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        line-height: 30px;
        transition: .3s ease-in-out;
        cursor: pointer;
        padding: 12px;
        gap: 20px;

        @media (max-width: 767.98px) {
            line-height: 20px;
        }

        @include darkMode {
            background-color: transparentize($black-2, .85);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
            //box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.25);
        }

        &:hover {
            transform: scale(1.04);
        }
    }

    &__profile-picture {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
    }

    &__name {
        color: $black-3;
        font-weight: bold;
        font-size: 20px;

        @include darkMode {
            color: $white-2;
        }

        @media (max-width: 575.98px) {
            font-size: 13px;
            letter-spacing: 1px;
            word-spacing: 0;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            font-size: 15px;
        }
    }

    &__date {
        padding-top: 2px;

        @include darkMode {
            color: $white-3;
        }

        @media (max-width: 575.98px) {
            font-size: 12px;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            font-size: 14px;
        }
    }

    &__lesson-text {
        @media (max-width: 575.98px) {
            font-size: 11px;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            font-size: 13px;
        }
    }

    &__lesson {
        img {
            width: 50px;


            @media (max-width: 991.98px) {
                width: 35px;
            }
        }
    }

    &__pagination {
        margin: 0 auto;

        &-number {
            background: $grey-1;
            color: $black-1;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.active {
                background: $primary-color;
                color: white;
            }
        }
    }
}