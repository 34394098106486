@import "src/scss/vars";

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    color: black;
    height: auto;
    width: 100vw;
    padding: 0 30px;
    justify-content: center;
    z-index: 999;

    @include darkMode() {
    }

    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }

    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        backdrop-filter: blur(10px);
        box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.3);

        @include darkMode() {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }

    &-container {
        justify-content: space-between;
        width: 100%;
        align-items: center;

        &__logo {
            margin-left: 0;
        }

        &__menu-icon {
            display: none;

            @media screen and (max-width: 992px) {
                display: flex;
                width: 100px;
                justify-content: flex-start;
            }
        }

        &__links {
            display: flex;
            margin-bottom: 0;
            align-items: center;
            padding: 0;

            &-item {
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }

            li {
                margin: 0 15px;
                list-style-type: none;

                &.main-logo {
                    margin: 0 15px 0 0;

                    img {
                        height: 60px;
                        object-fit: cover;
                        padding: 10px;
                    }

                    a {
                        padding: 0;

                        &:hover {
                            background-color: transparent;
                            cursor: pointer;
                        }
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 15px;
                    transition: all .2s ease-in-out;
                    color: black;

                    @include darkMode() {
                        color: $grey;
                    }

                    &:hover {
                        color: $primary-color;

                        @include darkMode() {
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        &__links-mobile {
            //display: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: white;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 0;
            padding: 0;
            list-style-type: none;
            gap: 10px;
            z-index: -3;
            transition: all 500ms cubic-bezier(1, 0.36, 0.41, 0.9);
            backdrop-filter: blur(10px) opacity(0);
            opacity: 0;
            overflow: hidden;
            border-radius: 10px;

            &.active {
                //display: flex;
                width: 100vw;
                backdrop-filter: blur(10px) opacity(1);
                background: rgba(255, 255, 255, 0.7);
                opacity: 1;
                border-radius: 0;

                .navbar-container__links-mobile-item {
                    opacity: 1;
                }
            }

            &-item {
                font-size: 1rem;
                transition: all 200ms ease-in-out;
                opacity: 0;

                a {
                    text-decoration: none;
                    color: black;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }

        &__user {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &-icon {
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    object-fit: cover;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &-popup {
        position: absolute;
        top: 80px;
        right: 40px;
        width: 300px;
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
        -webkit-box-shadow: 0 6px 15px 8px rgba(0, 0, 0, 0.42);
        box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.22);
        border-radius: 1.25rem;
        height: 0;
        overflow: hidden;
        transition: 0.4s ease-in-out;
        opacity: 0;
        background-color: rgba(255, 255, 255, 0.8);

        @include darkMode() {
            background-color: rgba(0, 0, 0, 0.2);
        }

        &.active {
            height: 460px;
            opacity: 1;
            padding: 30px;
        }

        &__user {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            align-items: center;
            justify-content: center;

            img {
                height: 80px;
                width: 80px;
                border-radius: 50%;
                object-fit: cover;
            }

            label {
                padding: 10px;
                font-size: 18px;
                font-weight: 400;
                display: flex;

                @include darkMode() {
                    color: white;
                }

                img {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &__button {
            padding: 10px;
            margin: 20px 0;
            border-radius: 45px;
            background-color: rgba(255, 255, 255, 0.418);
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
            color: black;
            transition: 0.4s ease-in-out;
            width: 100%;
            text-decoration: none;
            display: block;
            text-align: center;

            &:hover {
                background-color: $primary-color;
                box-shadow: 0 15px 20px $primary-color;
                color: #fff;
                transform: scale(1.08);
                cursor: pointer;
            }
        }
    }
}