@import "../../scss/vars";

.notification-page {
    //display: flex;
    //flex-direction: column;
    //width: 100vw;
    padding-top: 60px;
    min-height: 100vh;

    &__wrapper {
        margin-top: 50px;
        padding: 0;
        list-style: none;
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

    &__item {
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
        border-radius: $border-radius;
        padding: 20px;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__content {
        text-align: left;
        flex: 1;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
    }

    &__img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        object-fit: cover;
        padding: 2px;
    }

    &__empty-img {
        width: 100%;
        max-width: 300px;
    }
}