@import "../../scss/vars";


.reviews {

    .header-row {
        margin-bottom: 2rem;

        h1 {
            margin-bottom: 0 !important;
        }
    }

    .main-row {
        .main-row-1 {

            .main-row-1-total-rating {

                * {
                    padding: 0.5rem;
                }

                svg {
                    fill: #FFCE51;
                    height: 70px;
                    width: 70px;
                }

                h2 {
                    margin-bottom: 0;
                    text-align: center;
                }
            }

            .main-row-1-star-children {

                .star-ratings {

                    padding-bottom: 0.5rem;

                    svg {
                        fill: #FFCE51;
                        height: 20px;
                        width: 20px;
                    }

                    p {
                        text-align: center !important;
                        margin-bottom: 0 !important;
                        padding-left: 0.5rem;
                        padding-right: 1.5rem;
                        margin-top: 2px !important;
                    }

                    .star-bar {
                        width: 300px;
                        height: 20px;
                        border-radius: 16px;
                        margin-right: 5px;
                        overflow: hidden;
                        background-color: white;

                        @include darkMode {
                            background-color: $grey-1;
                        }
                    }

                    .fill {
                        height: 100%;
                        background: $primary-color;
                    }

                    .votes-number {
                        width: 80px;
                    }
                }

                @media (max-width: 299.98px) {
                    .star-bar {
                        width: 130px !important;
                    }
                }

                @media (min-width: 576px) and (max-width: 767.98px) {
                    .star-bar {
                    }

                    .votes-number {
                        width: 50px !important;
                    }
                }

                @media (min-width: 768px) and (max-width: 991.98px) {
                    .star-bar {
                        width: 200px !important;
                    }
                }

                @media (min-width: 992px) and (max-width: 1199.98px) {
                    .star-bar {
                        width: 250px !important;
                    }
                }
            }
        }

        .main-row-2 {

            .heading-elements {

                padding: 1rem 0;

                * {
                    margin-right: 1rem !important;
                }
                h4 {
                    margin-bottom: 0 !important;
                    margin-top: 2px !important;
                }

                p {
                    margin-bottom: 0 !important;
                    margin-top: 2px !important;
                    font-size: 16px !important;
                }

                svg {
                    fill: #FFCE51;
                    height: 20px;
                    width: 20px;
                }
            }

            p {
                font-size: 14px;
            }
        }
    }

}