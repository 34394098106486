@import "../../scss/vars";

.video-player {
    width: 100%;
    height: 100%;
    max-width: 1228px;
    border-radius: 25px;
    background: white;
    padding: 40px;

    @include darkMode {
        background: transparentize($black-3, .6);
    }

    &__wrapper {
        height: 700px;
        max-height: 735px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        max-width: 700px;
        margin: auto;
    }

    &__player {
        width: 100%;
        height: 700px;
        max-height: 735px;
        background: $grey-4;
        border-radius: 25px;

        &-self {
            width: 250px;
            height: 250px;
            position: absolute;
            object-fit: cover;
            bottom: 20px;
            right: 20px;
        }

        &-external {
            object-fit: cover;
        }
    }
}