@import "../../scss/vars";

.help-center {
    padding-top: 62px;
    margin-bottom: 5rem;

    &-title {
        font-weight: 600;
    }

    &-search-wrapper {
        box-sizing: border-box;
        border: 0;
        border-radius: 7px;
        padding: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        transition: 200ms ease-in-out;

        @include darkMode {
            background-color: transparentize($black-2, .85);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

            &:hover {
                box-shadow: 0 0 30px 0 $primary-color;
            }
        }
        
        @media (max-width: 450px) {

            width: 80%;
            
        }

    }

    &-search-input {
        //border: 0;
        width: 100%;
        //padding-left: 15px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &-search-icon {
        color: white;
        background-color: $primary-color;
        padding: 10px 15px;
        border-radius: 0 !important;
        border-top-right-radius: 7px !important;
        border-bottom-right-radius: 7px !important;
    }

    .button {
        padding: 12px 20px !important;
    }

    &-categories-wrapper {
        margin-top: 6rem;

        @media (min-width: 400px) and (max-width: 579.98px) {
            margin: 6rem 0.3rem;
        }

        @media (min-width: 580px) and (max-width: 750px) {
            margin: 6rem;
        }
    }

    &-category {
        box-sizing: border-box;
        background-color: $white-1;
        border: 0;
        border-radius: 12px;
        padding: 10px 25px;
        margin: 1rem;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        height: 150px;
        width: 150px;
        font-size: 13px;
        transition: 200ms ease-in-out;

        &:hover {
            box-shadow: 0 0 30px 0 $primary-color;
            cursor: pointer;
        }

        @include darkMode {
            background-color: transparentize($black-2, .85);
        };
    }

    &-category-icon {
        color: $primary-color;
        font-size: 45px;
    }
}