@import "../../../../scss/vars";

.account {

    min-height: 1000px;

    &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        &__label {
            font-size: 14px;
            font-weight: 500;
            color: black;
            margin: 1rem 0;

            @include darkMode{
                color: $grey-1;
            }
        }

        &__detail {
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            background-color: $grey-2;
            width: 100%;
            font-weight: 400;
            font-size: 18px;
            padding: 0.8rem 1rem;

            p {
                color: black;
                
                @media (max-width: 767.98px) {
                    font-size: 13px;
                }
            }

            svg {
                transition: 0.3s ease-in-out;
                &:hover {
                    cursor: pointer;
                    color: var(--primary-color, #50A6FF);
                    transform: scale(1.64);
                }
            }

            @include darkMode {
                background-color: $grey-1;
                color: #46464F;

                p {
                    color: #46464F;
                }
            }
        }
    }

    &-option {

        button {
            width: 50%;
            margin: 3rem 0;

            @media (max-width: 450px) {
                width: 100%;
            }

            @media (max-width: 767.98px) {
                font-size: 13px;
            }
        }
    }

    .reset-pwd {

        &__success {
            transform: translateY(120vh);
            transition: 0.4s ease-in-out;
            position: fixed;
            z-index: 9999999;
            bottom: 50px;
            right: 50px;
            background-color: white;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            padding: 30px 40px;
            &.show {
                transform: translateY(0);
            }
            .wrapper {
                display: flex;
                align-items: center;
                span {
                    margin-right: 20px;
                    font-size: 20px;
                    font-weight: 300;
                }
            }
        }
        &__popup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 999;
            transform: translateY(-110vh);
            transition: 0.3s ease-in-out;
            &.open {
                transform: translateY(0);
            }
            .container {
                position: absolute;
                width: 70vw;
                left: 15vw;
                height: 65vh;
                top: 15vh;
                background-color: white;
                border-radius: $border-radius;
                box-shadow: $box-shadow;
                .title {
                    font-size: 30px;
                    font-weight: 300;
                    padding: 20px 0 40px;
                }
                .pwd {
                    &-input {
                        display: flex;
                        flex-direction: column;
                        margin: 25px;
                        align-items: flex-start;
                        &__label {
                            font-size: 14px;
                            color: $grey-2;
                            font-weight: 200;
                        }
                        input {
                            margin-top: 8px;
                            width: 100%;
                            box-shadow: $box-shadow-light;
                        }
                    }
                    &-error {
                        padding: 10px 30px;
                        text-align: left;
                        span {
                            color: red;
                            font-size: 17px;
                            font-weight: 300;
                        }
                    }
                    &-save {
                        position: absolute;
                        bottom: 30px;
                        right: 30px;
                    }
                }
                .close {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}