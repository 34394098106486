@import "../../scss/vars";

.tutor-register {

    &__background {
        width: 50%;
        height: 100%;
        object-fit: cover;

        @include lg {
            display: none;
        }
    }

    &__left {
        width: 50%;
        height: 100%;
        padding: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include xl {
            padding: 60px;
        }

        @include lg {
            width: 60vw;
            height: 80vh;
            margin: auto;
            background: white;
            border-radius: 20px;
        }

        @include md {
            padding: 30px;
            width: 100%;
            height: 100%;
        }

        @include darkMode {
            background-color: $black-3;
        }

        &-title {
            margin-bottom: 80px;

            @include darkMode {

            }
        }

        &-form {
            text-align: left;

            &-label {
                font-weight: 500;
                letter-spacing: 0.06rem;
                font-size: 18px;
            }
        }
    }

    &__overlay {
        position: absolute;
        backdrop-filter: blur(15px);
        background-color: rgba(0, 0, 0, 0.296);
        width: calc(50vw - 10vw);
        height: calc(100vh - 15vh);
        left: 55vw;
        top: 7.5vh;
        border-radius: 1.25rem;
        transition: 0.9s ease-in-out;
        text-align: left;

        &-mini {
            border-radius: 1.25rem;
            backdrop-filter: blur(15px);
            background-color: rgba(255, 255, 255, 0.206);
            display: inline-block;

            span {
                font-size: 14px;

                &:nth-child(1) {
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }

        &-line {
            background-color: rgba(255, 255, 255, 0.453);
            height: 1px;
            width: 20%;
        }

        h2 {
            font-weight: 500;
        }

        &-down {
            position: absolute;
            bottom: 50px;
        }
    }

    &__logo {
        height: 60px;
    }

    &__step {
        min-width: 40px;
        min-height: 40px;
        padding: 5px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background: $grey-2;
        color: $black-1;

        &.active {
            background: $primary-color;
            color: white;
        }
    }

    &__id {
        width: 500px;
        max-width: 100%;
        border-radius: 12px;
    }
}