@import "../../scss/vars";

.questions {

    &-wrapper {
        border: 0;
        border-radius: 30px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        padding: 2rem;
        margin: 0.3rem;
        transition: 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 0 30px 0 $primary-color;
        }

        @include darkMode {
            background-color: transparentize($black-2, .85);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
            
            &:hover {
                box-shadow: 0 0 30px 0 $primary-color;
            }
        }
    }

    &-chaticon {
        color: mediumpurple;
        transition: 0.3s ease-in-out;
        font-size: 25px;

        &:hover {
            transform: scale(1.34);
            cursor: pointer;
        }
    }

    .answer-wrapper {
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-top: 0;
        padding: 15px;
        margin: 15px;
        font-size: 14px;
    }
}