@import "../../scss/vars";

.lesson-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
    background: white;
    font-size: 16px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

    @include darkMode {
        background: rgba(255, 255, 255, 0.15);
        box-shadow: unset;
    }

    td {
        padding: 18px 0 18px 24px;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
}