.about-us {

				&__line {
								position: absolute;
								top: 60px;
								left: 20px;
				}

				&__title {
								font-size: 54px;
								font-family: serif;
								font-weight: bold;
								text-align: center;
				}

				.about-us__img-wrapper {
								height: 20rem;
								width: 20rem;
								border-radius: 17px;
								margin: 0 15px;

								&.img1 {
												background: url('../../../public/resources/about/team/skarakasis.jpg') no-repeat center center;
												background-size: cover;
								}

								&.img2 {
												background: url('../../../public/resources/about/team/tasostsiam.jpg') no-repeat center center;
												background-size: cover;
								}

								&.img3 {
												background: url('../../../public/resources/about/team/thodoris.jpg') no-repeat center center;
												background-size: cover;
								}
				}

				&__section {
								min-height: 75vh;
				}
}