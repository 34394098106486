@import "../../scss/vars";

.four-squares {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16rem 0;

    @include lg() {
        flex-direction: column;
    }

    &__leftside-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 50%;

        @include lg {
            margin-bottom: 2rem;
        }

        h6 {
            color: $secondary-color;
            letter-spacing: 1px;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            font-weight: bold;

            span {
                width: 40px;
                height: 2px;
                margin-right: 10px;
                background: $secondary-color;
            }
        }

        h1 {
            font-weight: 700;
            margin-bottom: 1rem;
            line-height: 1.2;
            letter-spacing: -1px;
        }

        p {
            color: $grey-4;
            width: 70%;
            margin-bottom: 3rem;
            line-height: 1.5;

            @include darkMode {
                color: $grey-3;
            }
        }

        button {
            width: fit-content;
        }

    }

    &__rightside {
        display: flex;
        flex-direction: column;
        flex: 50%;

        &__row {
            display: flex;
            flex-direction: row;

            @include sm {
                flex-direction: column;
            }

            @include lg {
                margin: 0 -1rem !important;
            }

            &.first {
                margin-left: 3rem;

                @include xl {
                    margin-left: 0;
                }
            }

            &.second {
                margin-right: 3rem;

                @include xl {
                    margin-right: 0;
                }
            }

            &__square {
                box-sizing: border-box;
                border: 0;
                border-radius: 12px;
                //box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
                background: white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 1rem;
                padding: 5px;

                @include darkMode {
                    background: unset;
                    box-shadow: 1px 1px 17px 1px rgba(0, 0, 0, 0.25);
                }

                @include sm {
                    flex-direction: row;
                }

                * {
                    padding: 0 1rem;
                }

                h5 {
                    font-weight: bold;

                    @include xxl {
                        font-size: 16px;
                    }

                    @include sm {
                        margin-top: 10px;
                        padding: 0;
                    }
                }

                p {
                    color: $grey-4;
                    font-weight: normal;
                    line-height: 1.2;

                    @include darkMode {
                        color: $grey-3;
                    }

                    @include xxl {
                        font-size: 14px;
                    }

                    @include sm {
                        padding: 0;
                    }
                }

                &__img {
                    box-sizing: border-box;
                    border: 0;
                    border-radius: 50%;
                    height: 80px;
                    width: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 1rem 1rem;

                    &.first {
                        background: rgba(47, 211, 174, 0.1);
                        color: rgb(90, 218, 186);
                    }

                    &.second {
                        background: rgba(232, 106, 51, 0.1);
                        color: rgb(250, 158, 117);
                    }

                    &.third {
                        color: #FED24F;
                        background: rgba(254, 210, 79, 0.1);
                    }

                    &.fourth {
                        background: rgba(66, 161, 255, 0.1);
                        color: rgb(64, 161, 255);
                    }

                    @include xxl {
                        width: 70px;
                        height: 70px;
                    }

                    @include sm {
                        width: 60px;
                        height: 60px;
                    }

                    svg {
                        padding: 0;
                    }
                }
            }
        }
    }
}