@import "../../scss/vars";

.testimonials {
    margin: 10rem 0;
    padding: 0 1rem;

    &-title {
        margin-bottom: 5rem;
    }

    &-card {
        position: relative;
        box-sizing: border-box;
        background-color: $white-1;
        border-bottom-right-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 5px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
        padding: 1rem 3rem;
        margin: 1rem 0;

        @include darkMode {
            background-color: transparentize($white-3, .9);
        }

        @media (max-width: 767.98px) {
            padding: 0.8rem 3rem;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            padding: 0.8rem 1.5rem;
        }
    }

    img {
        width: 15%;
        position: absolute;
        top: -10%;
        left: -7%;
        border-radius: 50%;

        @media (max-width: 575.98px) {
            width: 50px;
            min-width: 50px;
            top: -10%;
            left: -5%;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            width: 13%;
            top: -20%;
            left: -6%;
        }
    }

    &-tutorName {
        font-weight: 700;


        @media (max-width: 767.98px) {
            font-size: 15px;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            font-size: 15px;
        }

        @media (min-width: 992px) and (max-width: 1199.98px) {
            font-size: 18px;
        }
    }

    &-stars {
        svg {
            width: 20px;
            height: 20px;
            min-width: 15px;
            min-height: 15px;
            fill: #ffcc00;
            filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));

            @include darkMode {
                filter: drop-shadow(0px 0px 3px rgba(253, 255, 254, 0.5));
            }
        }
    }

    &-text {
        padding: 1rem 0;
        opacity: .9;

        @include darkMode {
            color: rgba(255, 255, 255, 0.75);
        }

        @media (max-width: 767.98px) {
            font-size: 13.5px;
            padding: 0.7rem 0;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            font-size: 13.5px;
            padding: 0.7rem 0;
        }

        @media (min-width: 992px) and (max-width: 1199.98px) {
            font-size: 15px;
            padding: 0.85rem 0;
        }
    }

    &-studentName {
        color: $primary-color !important;
        font-size: 15px;

        @media (max-width: 767.98px) {
            font-size: 13px;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            font-size: 13px;
        }
    }
}