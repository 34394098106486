@import "../../scss/vars";


.contact {
    margin-top: 10rem;

    &-title {
        font-weight: 600;
    }

    &-subtitle {

    }

    &-to-help-center {
        margin-top: 8rem;

        button {
            @media (max-width: 700px) {
                width: 100% !important;
            }
        }
    }
}