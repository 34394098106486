@import "vars.scss";

body {
    h1, h2, h3, h4, h5, div, label, span, a {
        font-family: Inter, sans-serif;
    }

    overflow-x: hidden;

    &.modal-open {
        overflow: hidden;
    }
}

.app-wrapper {
    display: flex;
    overflow: hidden;
    height: 100vh;
}

.page, .container {
    padding-top: 62px;
}

:root {
    --navbar-app-width: 220px;
    --navbar-app-shrink-width: 80px;
    --container-app-padding: 50px;
    --container-app-left-padding: calc(var(--navbar-app-width) + var(--container-app-padding));
}

.container-app {
    overflow: auto;
    min-height: 100vh;
    padding: var(--container-app-padding);
    flex: 1;
}

.text-primary {
    color: $primary-color !important;
}

.text-secondary {
    color: $secondary-color !important;
}

.text-warning {
    color: $warning-color !important;
}

.input {
    @extend .input-shadow;
}

.input-2 {
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.286);
    padding: 8px 0;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        // color: white;
        font-family: 'Commissioner', sans-serif;
        font-weight: 200;
    }

    &-primary {

    }
}

.input-3 {
    border: none;
    outline: none;
    border-radius: 15px;
    box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.07);
    padding: 6px 20px;
}

.input-shadow {
    border: none;
    outline: none;
    border-radius: 10px;
    box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.07);
    padding: 10px 20px;
    transition: all .3s;
    background: white;

    select {
        background: transparent;
    }

    &:focus {
        box-shadow: 10px 10px 20px #d1d1d1,
        -5px -5px 20px rgba(244, 244, 244, 0.48);
    }
}

.input-border {
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
    outline: none;
    border-radius: 10px;
    transition: all .3s;
    padding: 6px 20px;

    &:focus {
        box-shadow: 10px 10px 20px #cccccc,
        -10px -10px 20px #ffffff;
    }
}

.input-neumorphism {
    border: none;
    outline: none;
    padding: 6px 20px;
    transition: all .3s;
    @extend .neumorphism;
    margin-top: 8px;

    &:focus {
        box-shadow: 10px 10px 20px #afafaf,
        -10px -10px 20px #ffffff,
        0 0 15px 1px $primary-color;

        //outline: 4px solid rgba(98, 37, 230, 0.14);
    }

    select {
        background: transparent;
    }
}

.select {
    &-main {
        border: 1px solid $primary-color;
        border-radius: $border-radius;
        padding: 7px 12px;
        width: 100%;
    }
}

.link {
    text-decoration: none;
    color: $primary-color !important;
    font-weight: 500;
    letter-spacing: 0.04rem;
    transition: 200ms ease-in-out;

    &-light {
        color: white !important;

        &:hover {
            color: white !important;
        }
    }

    &:hover {
        cursor: pointer;
        color: black !important;

        @include darkMode {
            color: white !important;
        }
    }
}

.w {
    &-90 {
        width: 90%;
    }
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.244);
}

.toggle {
    align-self: flex-end;
    justify-self: flex-end;

    input:checked + label {
        color: $primary-color;

        &:after {
            left: 100%;
            transform: translateX(-100%);
        }
    }

    input {
        &.active + label {
            color: $primary-color;

            &:after {
                left: 100%;
                transform: translateX(-100%);
            }
        }
    }

    .switch {
        position: relative;
        display: block;
        margin-inline: auto;
        cursor: pointer;

        width: var(--toggle-width);
        height: var(--toggle-height);

        color: var(--off);
        background-color: currentColor;

        border: var(--border-width) solid currentcolor;
        border-radius: var(--toggle-height);

        transition: color var(--transition-time) ease-out;

        padding: 0;

        &:after {
            content: "";
            position: absolute;
            background-color: white;

            /* 	Make it fit and make it round */
            height: 100%;
            aspect-ratio: 1;
            border-radius: 100%;

            /* 	Put it on the left */
            left: 0;
            transform: translateX(0);

            /* 	Set the animation speed and easing */
            transition: left var(--transition-time) ease-out,
            transform var(--transition-time) ease-out;
        }
    }
}

.fade-element {
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

a.link {
    font-weight: normal;
    color: $primary-color;
    text-decoration: none;

    &-strip {
        color: inherit;
        text-decoration: none;
    }


    &-subtle {
        color: rgba(0, 0, 0, 0.6);
    }
}

.button {
    border: none;
    color: white;
    border-radius: 50px;
    outline: none;
    padding: 12px 45px;
    transition: all 0.3s ease-in-out;
    display: block;
    background: $primary-color;
    position: relative;
    text-align: center;
    text-decoration: unset;

    a {
        text-decoration: inherit;
        color: inherit;
    }

    &-small {
        padding: 7px 30px;
        font-size: 16px;
    }

    &:hover {
        background: $primary-color;
        box-shadow: 0 5px 20px $primary-color;
        cursor: pointer;
    }

    &.active {
        background-color: $primary-color;
        color: white;

        &:hover {

        }
    }

    &-fill {
        background-color: $primary-color;
        color: white;

        &:hover {
            background-color: transparent;
            color: $primary-color;
        }
    }

    &-secondary {
        background-color: $secondary-color;
        color: white;

        &:hover {
            background-color: $secondary-color;
            box-shadow: 0 5px 20px $secondary-color;
        }
    }

    &-warning {
        background-color: $warning-color;
        color: white;

        &:hover {
            background-color: $warning-color;
            box-shadow: 0 5px 20px $warning-color;
        }
    }

    &-gray {
        background: $grey-2;
        color: black;

        &:hover {
            background-color: $primary-color;
            color: white;
        }
    }

    &-filled {
        background: $primary-color;
        color: white;

        &:hover {
            background: transparent;
            color: $primary-color;
            border: 2px solid $primary-color;
        }

        &:focus {
            background: darken($primary-color, 10%);
            color: white;
            border: 2px solid darken($primary-color, 10%);
        }
    }
}

button {
    @extend .button;
}

.input-label {
    font-size: 17px;
    font-weight: normal;
    margin-bottom: 10px;
}

:not(.no-scrollbar) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $primary-color;
    }
}

.neumorphism {
    border-radius: 10px;
    box-shadow: 10px 10px 20px #afafaf,
    -10px -10px 20px #ffffff;
    background: linear-gradient(145deg, #f6f6f6, #ececec);
}

.glassmorphism {
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(10px);

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -40;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(15px);
        border: 0;
        border-radius: 8px;
        box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    }


    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -50;
        background-image: linear-gradient(144deg, #AF40FF 49%, #5B42F3 50%, #00DDEB);
        border: 0;
        border-radius: 8px;
        box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    }
}

.input {
    &__container {
        position: relative;
        background: #ddddddc7;
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        gap: 5px;
        border-radius: 22px;
        transition: transform 400ms;
        transform-style: preserve-3d;
        flex-direction: column;
        color: white;
        width: 100%;
        align-items: flex-start;
    }

    &__shadow {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        filter: blur(30px);
        border-radius: 20px;
        background-color: #999cff;
        background-image: radial-gradient(at 85% 51%, hsla(60, 60%, 61%, 1) 0px, transparent 50%),
        radial-gradient(at 74% 68%, hsla(235, 69%, 77%, 1) 0px, transparent 50%),
        radial-gradient(at 64% 79%, hsla(284, 72%, 73%, 1) 0px, transparent 50%),
        radial-gradient(at 75% 16%, hsla(283, 60%, 72%, 1) 0px, transparent 50%),
        radial-gradient(at 90% 65%, hsla(153, 70%, 64%, 1) 0px, transparent 50%),
        radial-gradient(at 91% 83%, hsla(283, 74%, 69%, 1) 0px, transparent 50%),
        radial-gradient(at 72% 91%, hsla(213, 75%, 75%, 1) 0px, transparent 50%);
    }

    &__element {
        width: 100%;
        border-radius: 20px;
        outline: none;
        border: none;
        padding: 8px 15px;
        position: relative;
        background: #ffffff85;
    }
}

.accordion {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24) !important;
    border-radius: $border-radius !important;
    overflow: hidden;

    &-item {
        border-left: none !important;
        border-right: none !important;
        border-radius: 0 !important;

        .accordion-button {
            border-radius: 0 !important;
        }

        &:first-of-type {
            border-top: none !important;
        }


        &:last-of-type {
            border-bottom: none !important;
        }
    }
}

.hide {
    display: none !important;
}