@import "src/scss/vars";

.navbar {
				&-container {
								&__user {
												&-notification {
																position: relative;
																border-radius: 50%;
																width: 32px;
																height: 32px;
																display: flex;
																align-content: center;
																align-items: center;
																justify-content: center;
																transition: 300ms ease-in-out;

																svg {
																				fill: black;

																				@include darkMode() {
																								fill: white;
																				}
																}

																&:hover {
																				background-color: rgba(0, 0, 0, 0.3);
																				cursor: pointer;

																				svg {
																								fill: white;

																								@include darkMode() {
																												fill: $grey-3;
																								}
																				}
																}
												}
								}
				}
}