@import "../../scss/vars";

.browse-tutors {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 72px;
        gap: 30px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 254px;
        height: 280px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        cursor: pointer;
        transition: .3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    &__user-pic {
        width: 100%;
        height: 463px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background: #f9b44d;
        object-fit: cover;
    }

    &__footer {
        position: absolute;
        bottom: 6px;
        width: 240px;
        height: 80px;
        background: rgba(34, 34, 42, 0.80);
        padding: 17px 0;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__name {
        font-size: 20px;
        color: white;
    }

    &__star {
        font-size: 14px;
        color: $grey-1;
    }

    &__info {
        font-size: 14px;
        color: $grey-1;
    }

    // slick styling

    .slick-track {
        padding: 20px 0;
    }

    .slick-list {
        margin: 0 20px;
    }

    .slick-prev,
    .slick-next {
        color: $black-2;
        background-color: $grey-1;
        border-radius: 50%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        transition: 200ms ease-in-out;

        &:before {
            display: none;
        }
    }

    .slick-prev {
        left: -50px;
    }

    .slick-next {
        right: -50px;
    }

    .slick-prev:hover,
    .slick-next:hover {
        background-color: $primary-color;
        color: white;
    }
}