@import "../../scss/vars";

.hero {
    width: 100%;
    height: 60vh;
    min-height: 33rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;

    @include lg {
        flex-direction: column;
    }

    &__wrapper {
        max-width: 650px;
        text-align: left;
        position: relative;
    }

    &__title {
        font-size: 46px;
        font-weight: 700;
        margin-bottom: 30px;
        color: $black-1 !important;
        line-height: 1.4;
        letter-spacing: -1px;

        @include darkMode {
            color: $white-1 !important;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 50px;
        max-width: 500px;
        color: $black-4 !important;
        line-height: 1.4;

        @include darkMode {
            color: $white-2 !important;
        }
    }

    &__laptop {
        max-width: 600px;
        width: 100%;

        //@include darkMode {
        //    display: none;
        //}
        //
        //&-dark {
        //    display: none;
        //
        //    @include darkMode {
        //        display: block;
        //    }
        //}
    }

    &__cross {
        position: absolute;
        bottom: 0;
        right: 0;
        animation: moveUpDown 2s ease-in-out infinite;
    }

    &__rumbus {
        position: absolute;
        top: 0;
        left: 50px;

        @include darkMode {
            filter: invert(1) hue-rotate(180deg);
        }
    }

    &__lines {
        position: absolute;
        top: 0;
        left: -150px;
        transform: translateY(-150%);

        @include darkMode {
            filter: invert(.8);
        }
    }
}

@keyframes moveUpDown {
    0%, 100% {
        transform: translateY(0); /* Start and end position */
    }
    50% {
        transform: translateY(-20px); /* Middle position (move up) */
    }
}