@import "../../scss/vars";

.partners {
    width: 100%; /* Half of the screen size */
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center the stars horizontally */
    align-items: center; /* Center the stars vertically */
    margin: 10rem 0;

    @media (max-width: 991.98px) {
        width: 100%;
    }

    &__carousel {
        width: 100%; /* The carousel container is already half of the screen size */
        /* Hide the overflow and create a scroll effect */
        overflow: hidden;
        white-space: nowrap;

        &__wrapper {
            display: inline-block; /* Keep the icons in a row */
            /* Set the animation properties */
            animation: carouselAnimation 10s linear infinite;
        }
    }

    &__icon-element {
        width: 20%; /* Each icon takes 20% width (5 icons total) */
        min-width: 80px;
        height: 100px; /* Adjust the height of each icon as per your requirement */
        flex-shrink: 0; /* Prevent icons from shrinking */
        padding: 0 1rem;

        @include darkMode {
            filter: invert(1) hue-rotate(180deg);
        }
    }
}

/* Define the animation keyframes */
@keyframes carouselAnimation {
    0% {
        transform: translateX(0); /* Start at the original position */
    }
    100% {
        transform: translateX(-100%); /* Move to the left by the total width of all icons (100%) */
    }
}
