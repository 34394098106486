@import "../../scss/vars";

.tickets {
    border-radius: 12px;
    background: transparentize($grey-1, .8);
    padding: 15px;
    display: flex;
    gap: 8px;
    align-items: center;

    svg {
        fill: $primary-color;
    }

    @include darkMode {

    }
}