@import "../../scss/vars";

.your-classes {
    height: 100%;
    display: flex;
    //overflow: hidden;
    position: relative;

    &__wrapper {
        width: 100%;
    }

    &__hours {
        position: relative;
    }

    &__hour {
        font-size: 14px;
        padding-right: 10px;
    }

    &__current {
        width: calc(100% + 15px);
        height: 2px;
        position: absolute;
        background: $primary-color;
        display: flex;
        align-items: center;
        z-index: 2;
        left: -15px;
        opacity: .6;
        pointer-events: none;

        &-circle {
            border-radius: 50%;
            background: $primary-color;
            width: 12px;
            height: 12px;
        }

        &-line {

        }
    }

    &__slot {
        flex: 1;
        width: 90%;
        position: relative;

        &-separator {
            height: 2px;
            width: 100%;
            border-top: 1px dashed transparentize($black-1, .7);

            @include darkMode {
                border-top: 1px dashed transparentize($grey-1, .7);
            }
        }

        &-box {
            position: absolute;
            top: calc(50% + 5px);
            left: 10%;
            height: calc(100% - 10px);
            width: 80%;
            border-radius: 12px;
            background: $grey-4;
            padding: 10px;
            cursor: pointer;
            z-index: 1;
        }

        &-title {
            color: $grey-1;
        }

        &-subtitle {
            color: $grey-3;
            font-size: 14px;
        }
    }
}